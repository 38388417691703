














import BrevetArchive from '@/components/BrevetArchive.vue';
import BrevetListItem from '@/components/BrevetListItem.vue';
import SetTitleMutation from '@/store/models/setTitleMutation';
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  components: {
    BrevetListItem,
    BrevetArchive,
  },
  computed: {
    ...mapGetters(['oldBrevets', 'newBrevets', 'isLoading', 'getClubSelection']),
  },
  watch: {
    getClubSelection() {
      // eslint-disable-next-line no-use-before-define
      this.$store.dispatch('listBrevets', (this as BrevetList).getClubSelection.slice().sort());
    },
  },
})
export default class BrevetList extends Vue {
  getClubSelection!: string[];
  $title!: string;

  mounted(): void {
    this.$store.commit(new SetTitleMutation(this.$t('Route.brevetList').toString()));
    this.$store.dispatch('listBrevets', this.getClubSelection.slice().sort());
  }

  updated(): void {
    this.$store.commit(new SetTitleMutation(this.$t('Route.brevetList').toString()));
  }
}

