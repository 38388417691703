






















import Brevet from '@/models/brevet';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class BrevetListItem extends Vue {
  @Prop() private brevet?: Brevet;
}

Vue.component('app-brevet-list-item', BrevetListItem);
