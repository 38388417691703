



















import ClubSelector from '@/components/ClubSelector.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import LogInOut from '@/components/LogInOut.vue';
import router, {routes} from '@/router';
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  components: {
    ClubSelector,
    LocaleSwitcher,
    LogInOut,
  },
  computed: {
    ...mapGetters(['getTitle']),
  },
  watch: {
    $route(value) {
      const menuRoute = routes.find((item) => item.name === value.name);
      if (menuRoute) {
        // eslint-disable-next-line no-use-before-define
        (this as NavigationMenu).activeIndex = menuRoute.meta.id;
      }
    },
  },
})
export default class NavigationMenu extends Vue {
  menuItems = routes.filter((item) => item.meta.showInMenu);
  activeIndex = '1';

  mounted(): void {
    // set a menu selection based on the route
    const menuRoute = routes.find((item) => item.name === router.currentRoute.name);
    if (menuRoute) {
      this.activeIndex = menuRoute.meta.id;
    }
  }

  onSelect(key: string, keyPath: string[]): void {
    const route = this.menuItems.find((item) => item.meta.id === key);
    if (route && route.path !== router.currentRoute.path) {
      router.push(route.path);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.dropdown.hide();
  }
}

Vue.component('app-navigation-menu', NavigationMenu);
