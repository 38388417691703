













import router from '@/router';
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
})
export default class LogInOut extends Vue {
  isLoggedIn!: boolean;

  // eslint-disable-next-line class-methods-use-this
  openProfile(): void {
    router.push({name: 'profile'});
  }

  // eslint-disable-next-line class-methods-use-this
  logIn(): void {
    // FIXME : update status based on auth reply
    window.location.href = [process.env.VUE_APP_AWS_AUTH,
      '/login?client_id=', process.env.VUE_APP_AWS_CLIENT_ID,
      '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile',
      '&redirect_uri=', process.env.VUE_APP_AWS_S3, '/profile'].join('');
  }
}

Vue.component('app-login-out', LogInOut);
