
















import Club from '@/models/club';
import ToggleClubSelectionMutation from '@/store/models/toggleClubSelectionMutation';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

type ClubMenuItem = Club & { selected: boolean };

@Component({
  watch: {
    clubs(items: Club[]) {
      // eslint-disable-next-line no-use-before-define
      (this as ClubSelector).options = items.map((item) => ({
        ...item,
        // eslint-disable-next-line no-use-before-define
        selected: (this as ClubSelector).selection.includes(item.id.toString()),
      }));
    },
    selection(items: string[]) {
      // eslint-disable-next-line no-use-before-define
      (this as ClubSelector).options.forEach((club) => {
        // eslint-disable-next-line no-param-reassign
        club.selected = items.includes(club.id.toString());
      });
    },
  },
  computed: {
    ...mapGetters({clubs: 'getClubs', selection: 'getClubSelection'}),
  },
})
export default class ClubSelector extends Vue {
  @Prop() private rootIndex?: string;
  clubs!: Club[];
  selection!: string[];

  options: ClubMenuItem[] = [
    {
      id: 0,
      country: 'any',
      name: {
        default: 'All',
        ru: 'Все',
      },
      selected: true,
    },
  ];

  mounted(): void {
    this.$store.dispatch('listClubs');
  }

  onCheck(item: { index: string }): void {
    this.$store.commit(new ToggleClubSelectionMutation(item.index));
  }
}

Vue.component('app-club-selector', ClubSelector);
