















import BrevetListItem from '@/components/BrevetListItem.vue';
import Brevet from '@/models/brevet';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
    BrevetListItem,
  },
})
export default class BrevetArchive extends Vue {
  @Prop() private brevets?: Brevet[];
  visible: string[] = [];
}
Vue.component('app-brevet-archive', BrevetArchive);
