import {Tokens} from '@/models/tokens';

export default class SetTokensMutation {
  type = 'setTokens';
  tokens: Tokens;

  constructor(tokens: Tokens) {
    this.tokens = tokens;
  }
}
