












































import {UserInfo} from '@/models/user-info';
import {SetUserInfoMutation} from '@/store/models';
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters({userInfo: 'getUserInfo'}),
  },
})
export default class Profile extends Vue {
  isLoggedIn!: boolean;
  loginDialogVisible = false;
  loginUrl = [process.env.VUE_APP_AWS_AUTH,
    '/login?client_id=', process.env.VUE_APP_AWS_CLIENT_ID,
    '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile',
    '&redirect_uri=', process.env.VUE_APP_AWS_S3, '/profile'].join('');

  // eslint-disable-next-line class-methods-use-this
  mounted(): void {
    console.log('query', this.$route.query, 'login', this.isLoggedIn);
    const {code} = this.$route.query;
    if (code && !this.isLoggedIn) {
      this.$store.dispatch('getTokens', code);
    }
  }

  logIn(): void {
    console.log(' do login ', this);
    window.location.href = this.loginUrl;
    // this.loginDialogVisible = true;
  }

  logOut(): void {
    this.$store.commit(new SetUserInfoMutation({} as UserInfo));
    window.location.href = [process.env.VUE_APP_AWS_AUTH,
      '/logout?client_id=', process.env.VUE_APP_AWS_CLIENT_ID,
      '&logout_uri=', process.env.VUE_APP_AWS_S3, '/profile'].join('');
  }
}

Vue.component('app-profile', Profile);
