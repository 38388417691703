import {State} from '@/models/state';
import {SetTokensMutation, SetUserInfoMutation} from '@/store/models';
import SetLoadingMutation from '@/store/models/setLoadingMutation';
import axios from 'axios';
import querystring from 'querystring';
import {ActionContext} from 'vuex';

export default function (context: ActionContext<State, State>,
  code: string): Promise<string | void> {
  context.commit(new SetLoadingMutation(true));

  const tokenUrl = `${process.env.VUE_APP_AWS_AUTH}/oauth2/token`;
  return axios.post(tokenUrl, querystring.stringify({
    grant_type: 'authorization_code',
    client_id: process.env.VUE_APP_AWS_CLIENT_ID,
    redirect_uri: `${process.env.VUE_APP_AWS_S3}/profile`,
    // refresh_token: 'xxx',
    code,
  }), {timeout: 60000})
    .then((response) => response.data)
    .then((tokens) => {
      console.log('token response', tokens);
      context.commit(new SetTokensMutation(tokens));
      const userInfoUrl = `${process.env.VUE_APP_AWS_AUTH}/oauth2/userInfo`;
      return axios.get(userInfoUrl, {
        timeout: 60000,
        headers: {
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      })
        .then((response) => response.data)
        .then((info) => {
          console.log('userInfo response', info);
          context.commit(new SetUserInfoMutation(info));
          return info;
        });
    })
    .catch((error) => {
      // TODO: set a state error and show
      console.error('get doc', error.message, error);
    })
    .finally(() => context.commit(new SetLoadingMutation(false)));
}
