

























































import CheckpointListItem from '@/components/CheckpointListItem.vue';
import NotFoundPlug from '@/components/NotFoundPlug.vue';
import Brevet from '@/models/brevet';
import SetTitleMutation from '@/store/models/setTitleMutation';
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  components: {
    CheckpointListItem,
    NotFoundPlug,
  },
  computed: {
    brevet() {
      return this.$store.getters.getBrevet(this.$route.params.uid);
    },
    ...mapGetters(['isLoading']),
  },
})
export default class BrevetInfo extends Vue {
  visible: string[] = [];
  brevet!: Brevet;

  mounted(): void {
    this.$store.commit(new SetTitleMutation(this.$t('BrevetInfo.title').toString()));
    this.$store.dispatch('getBrevet', this.$route.params.uid);
  }

  updated(): void {
    const title = this.brevet
      ? this.$t('Route.brevetInfo', {name: this.brevet.name})
      : this.$t('BrevetInfo.title');
    this.$store.commit(new SetTitleMutation(title.toString()));
  }
}
