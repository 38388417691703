import {UserInfo} from '@/models/user-info';

export default class SetUserInfoMutation {
  type = 'setUserInfo';
  info: UserInfo;

  constructor(info: UserInfo) {
    this.info = info;
  }
}
