










import NavigationMenu from '@/components/NavigationMenu.vue';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
    NavigationMenu,
  },
})
export default class App extends Vue {
}
