




import {Component, Vue} from 'vue-property-decorator';

@Component
export default class NotFoundPlug extends Vue {
}

Vue.component('app-not-found-plug', NotFoundPlug);
