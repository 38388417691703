
















import languages from '@/locales/languages.json';
import SetLocaleMutation from '@/store/models/setLocaleMutation';
import {Component, Prop, Vue} from 'vue-property-decorator';

type LanguageNames = { [key: string]: string };
type Language = { value: string, label: string };

@Component({
  computed: {
    locale(): string {
      const locale = this.$store.getters.getLocale;
      this.$i18n.locale = locale;
      return locale;
    },
  },
})
export default class LocaleSwitcher extends Vue {
  @Prop() private rootIndex?: string;
  locale?: string;
  locales: string[] = (process.env.VUE_APP_I18N_SUPPORTED_LOCALES || '').split(',') || [];
  // default locale of the build
  options: Language[] = [{
    value: process.env.VUE_APP_I18N_LOCALE || 'en',
    label: process.env.VUE_APP_I18N_LOCALE || 'en',
  }];

  // eslint-disable-next-line class-methods-use-this
  mounted(): void {
    const names = languages as LanguageNames;
    // replace locales with a list of supported
    this.options = this.locales.map((value: string) => ({label: names[value], value}));
  }

  switchLocale(item: { index: string }): void {
    if (this.locale !== item.index) {
      this.$store.commit(new SetLocaleMutation(item.index));
    }
  }
}

Vue.component('app-locale-switcher', LocaleSwitcher);
