



















import Checkpoint from '@/models/checkpoint';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CheckpointListItem extends Vue {
  @Prop() private checkpoint?: Checkpoint;
}

Vue.component('app-checkpoint-list-item', CheckpointListItem);
